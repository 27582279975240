import black_1 from '../assets/black-1.png';
import purple_1 from '../assets/purple-1.png';
import blue_1 from '../assets/blue-1.png';
import blue_2 from '../assets/blue-2.png';
import blue_4 from '../assets/blue-4.png';
import yellow_1 from '../assets/yellow-1.png';
import yellow_2 from '../assets/yellow-2.png';
import yellow_4 from '../assets/yellow-4.png';
import green_1 from '../assets/green-1.png';
import green_2 from '../assets/green-2.png';
import green_4 from '../assets/green-4.png';
import red_1 from '../assets/red-1.png';
import red_2 from '../assets/red-2.png';
import red_4 from '../assets/red-4.png';
import white_1 from '../assets/white-1.png';
import white_2 from '../assets/white-2.png';
import white_3 from '../assets/white-3.png';
import pumpkin_1 from '../assets/pumpkin-1.png';
import pumpkin_6 from '../assets/pumpkin-6.png';
import flower from '../assets/flower.png';

export const initialTokenState = [
  {
    id: "0",
    name: "Pumpkin 1",
    count: 1,
    imageurl: pumpkin_1,
    description: "Pumpkin value 1",
    isExpansion: false,
    cherryBombValue: 0,
  },
  {
    id: "1",
    name: "Pumpkin 6",
    count: 0,
    imageurl: pumpkin_6,
    description: "Pumpkin value 6",
    isExpansion: true,
    cherryBombValue: 0,
  },
  {
    id: "2",
    name: "Garden spider 1",
    count: 1,
    imageurl: green_1,
    description: "Garden spider value 1",
    isExpansion: false,
    cherryBombValue: 0,
  },
  {
    id: "3",
    name: "Garden spider 2",
    count: 0,
    imageurl: green_2,
    description: "Garden spider value 2",
    isExpansion: false,
    cherryBombValue: 0,
  },
  {
    id: "4",
    name: "Garden spider 4",
    count: 0,
    imageurl: green_4,
    description: "Garden spider value 4",
    isExpansion: false,
    cherryBombValue: 0,
  },
  {
    id: "5",
    name: "Cherry bomb 1",
    count: 4,
    imageurl: white_1,
    description: "Cherry bomb  value 1",
    isExpansion: false,
    cherryBombValue: 1,
  },
  {
    id: "6",
    name: "Cherry bomb 2",
    count: 2,
    imageurl: white_2,
    description: "Cherry bomb  value 2",
    isExpansion: false,
    cherryBombValue: 2,
  },
  {
    id: "7",
    name: "Cherry bomb 3",
    count: 1,
    imageurl: white_3,
    description: "Cherry bomb  value 3",
    isExpansion: false,
    cherryBombValue: 3,
  },
  {
    id: "8",
    name: "Toadstool 1",
    count: 0,
    imageurl: red_1,
    description: "Toadstool value 1",
    isExpansion: false,
    cherryBombValue: 0,
  },
  {
    id: "9",
    name: "Toadstool 2",
    count: 0,
    imageurl: red_2,
    description: "Toadstool value 2",
    isExpansion: false,
    cherryBombValue: 0,
  },
  {
    id: "10",
    name: "Toadstool 4",
    count: 0,
    imageurl: red_4,
    description: "Toadstool value 4",
    isExpansion: false,
    cherryBombValue: 0,
  },
  {
    id: "11",
    name: "Crow Skull 1",
    count: 0,
    imageurl: blue_1,
    description: "Crow Skull value 1",
    isExpansion: false,
    cherryBombValue: 0,
  },
  {
    id: "12",
    name: "Crow Skull 2",
    count: 0,
    imageurl: blue_2,
    description: "Crow Skull value 2",
    isExpansion: false,
    cherryBombValue: 0,
  },
  {
    id: "13",
    name: "Crow Skull 4",
    count: 0,
    imageurl: blue_4,
    description: "Crow Skull value 4",
    isExpansion: false,
    cherryBombValue: 0,
  },
  {
    id: "14",
    name: "Mandrake 1",
    count: 0,
    imageurl: yellow_1,
    description: "Mandrake value 1",
    isExpansion: false,
    cherryBombValue: 0,
  },
  {
    id: "15",
    name: "Mandrake 2",
    count: 0,
    imageurl: yellow_2,
    description: "Mandrake value 2",
    isExpansion: false,
    cherryBombValue: 0,
  },
  {
    id: "16",
    name: "Mandrake 4",
    count: 0,
    imageurl: yellow_4,
    description: "Mandrake value 4",
    isExpansion: false,
    cherryBombValue: 0,
  },
  {
    id: "17",
    name: "African death's head hawkmoth 1",
    count: 0,
    imageurl: black_1,
    description: "African death's head hawkmoth 1",
    isExpansion: false,
    cherryBombValue: 0,
  },
  {
    id: "18",
    name: "Ghost's breath 1",
    count: 0,
    imageurl: purple_1,
    description: "Ghost's breath value 1",
    isExpansion: false,
    cherryBombValue: 0,
  },
  {
    id: "19",
    name: "Locoweed 1",
    count: 0,
    imageurl: flower,
    description: "Locoweed value 1",
    isExpansion: true,
    cherryBombValue: 0,
  },
];