import React from 'react';

const IconWithCounter = ({ percent, image, desc, number }) => {
  const badgeStyle = {
    position: 'absolute',
    top: 10,
    right: 10,
    backgroundColor: 'black',
    borderColor: 'white',
    borderWidth: '1px',
    color: 'white',
    padding: '2px 8px',
    borderRadius: '50%',
    fontSize: '16px',
    fontWeight: 600,
  };

  const leftBadge = {
    position: 'absolute',
    bottom: 5,
    left: 25,
    backgroundColor: 'black',
    color: 'white',
    padding: '2px 8px',
    borderRadius: '10%',
    fontSize: '16px',
    fontWeight: 600,
  };

  return (
    <div style={{ position: 'relative' }}>
      <img src={image} alt={desc} width="120" height="115" />
      {percent > 0 && (
        <span style={leftBadge}>{percent}%</span>
      )}
      {number > 0 && ( // Only render the badge if there's a number
        <span style={badgeStyle}>{number}</span>
      )}
    </div>
  );
};

export default IconWithCounter;