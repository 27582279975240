import { useState } from 'react';
import { initialTokenState } from './data/tokenData';
import CalculatorScreen from './screens/CalculatorScreen';
import SetupScreen from './screens/SetupScreen';
import './App.css';


function App() {
  const [showCalculator, setShowCalculator] = useState(false);
  const [tokens, setTokens] = useState(initialTokenState.slice());

  return (
    <div className="main">
      <header className="header">
        <span className="title">QuackCalc</span>
      </header>
      <div className="content">
        {
          showCalculator ?
            <CalculatorScreen toggleScreen={setShowCalculator} tokens={tokens.slice()} /> :
            <SetupScreen toggleScreen={setShowCalculator} setTokens={setTokens} tokens={tokens} />
        }
      </div>
    </div>
  );
}

export default App;
