import { useState, useEffect, useMemo } from "react";
import { IconCircleMinus, IconCircleX } from "@tabler/icons-react";
import IconWithCounter from "../components/IconWithCounter";
import "./CalculatorScreen.css";

function CalculatorScreen({ toggleScreen, tokens }) {

  const getTokensWithCount = () => tokens.filter(token => token.count > 0);

  const [activeTokens, setTokens] = useState(getTokensWithCount());
  const [usedTokens, setUsedTokens] = useState([]);
  const [chanceOfExplosion, setChanceOfExplosion] = useState(0);
  const [isExploded, setIsExploded] = useState(false);
  const [usedCherryBombTotal, setUsedCherryBombTotal] = useState(0);

  const totalAvailableCount = useMemo(() => {
    return activeTokens.reduce(
      (acc, token) => acc + (token.count > 0 ? token.count : 0),
      0
    );
  }, [activeTokens]);

  const tokensWithPercentage = useMemo(() => {
    return activeTokens.map((token) => {
      const count = token.count;
      const percentage = count > 0 ? ((count / totalAvailableCount) * 100).toFixed(2) : 0;
      return { ...token, count, percentage };
    });
  }, [activeTokens, totalAvailableCount]);

  const handleCountChange = (id, change) => {
    setTokens((prevTokens) =>
      prevTokens.map((token) =>
        token.id === id
          ? { ...token, count: Math.max(0, token.count + change) }
          : token
      )
    );
    // Find the token to update in usedTokens
    const usedTokenIndex = usedTokens.findIndex((token) => token.id === id);

    // Handle cases: existing used token or new used token
    if (usedTokenIndex > -1) {
      const currentUsedToken = usedTokens[usedTokenIndex];

      if (change > 0) {
        // Decrement count, and remove if count reaches 0
        const decrementedCount = currentUsedToken.count - 1;

        if (decrementedCount === 0) {
          const updatedUsedTokens = [
            ...usedTokens.slice(0, usedTokenIndex),
            ...usedTokens.slice(usedTokenIndex + 1),
          ];

          setUsedTokens(updatedUsedTokens);
        } else {
          const updatedUsedTokens = [
            ...usedTokens.slice(0, usedTokenIndex),
            { ...currentUsedToken, count: decrementedCount },
            ...usedTokens.slice(usedTokenIndex + 1),
          ];

          setUsedTokens(updatedUsedTokens);
        }
      } else {
        // Increment count
        const updatedUsedTokens = [
          ...usedTokens.slice(0, usedTokenIndex),
          { ...currentUsedToken, count: currentUsedToken.count + 1 },
          ...usedTokens.slice(usedTokenIndex + 1),
        ];

        setUsedTokens(updatedUsedTokens);
      }
    } else {
      const tokenToUpdate = tokens.find((token) => token.id === id);
      setUsedTokens((prevUsedTokens) => [...prevUsedTokens, { ...tokenToUpdate, count: 1 }]);
    }
  };

  const buyPhase = () => {
    // Call the parent function passed as a prop
    toggleScreen(false);
  };

  useEffect(() => {
    document.body.scrollIntoView({ behavior: "smooth" });
  }, []);

  useEffect(() => {
    const updatedUsedCherryBombTotal = usedTokens.reduce(
      (acc, token) => acc + (token.cherryBombValue * token.count || 0),
      0
    );
    setUsedCherryBombTotal(updatedUsedCherryBombTotal);
    if (usedCherryBombTotal > 7) setIsExploded(true);
  }, [usedTokens, usedCherryBombTotal]);

  useEffect(() => {
    const probability = tokensWithPercentage.reduce((acc, token) => {
      const exceedingValue = Math.max(0, token.cherryBombValue + usedCherryBombTotal - 7);
      const tokenDrawProbability = parseFloat(token.percentage);
      return acc + (exceedingValue > 0 ? tokenDrawProbability : 0);
    }, 0);

    setChanceOfExplosion(probability);
  }, [tokensWithPercentage, usedTokens, usedCherryBombTotal]);

  return (
    <>
      <h2>{isExploded ? "💥💥💥" : `💣 ${chanceOfExplosion}%`}</h2>
      <h2>Bag</h2>
      <div className="tokens bag-tokens">
        {tokensWithPercentage.map((token, index) => (
          token.count > 0 && (
            <div key={index}>
              <IconWithCounter
                percent={token.percentage}
                image={token.imageurl}
                desc={token.name}
                number={token.count}
              />
              <button onClick={() => handleCountChange(token.id, -1)}>
                <IconCircleMinus color="black" size={48} />
              </button>
            </div>
          )
        ))}
      </div>
      {(usedTokens.length > 0) &&
        (<>
          <h2>Tokens drawn</h2>
          <div className="tokens used-tokens">
            {usedTokens.map((token, index) => (
              <div key={index}>
                <IconWithCounter image={token.imageurl} desc={token.name} number={token.count} />
                <button onClick={() => handleCountChange(token.id, 1)}>
                  <IconCircleX color="black" size={48} />
                </button>
              </div>
            ))}
          </div>
        </>
        )}
      <div className="button-container">
        <button onClick={buyPhase}>Return to shop</button>
      </div>
    </>
  )
}

export default CalculatorScreen;