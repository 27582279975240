// Screen to add the tokens for a given round
import { useState } from 'react';
import { initialTokenState } from '../data/tokenData';
import ToggleSwitch from '../components/ToggleSwitch';
import IconWithCounter from '../components/IconWithCounter';
import { IconCircleMinus, IconCirclePlus } from '@tabler/icons-react';
import './SetupScreen.css';

function SetupScreen({ toggleScreen, tokens, setTokens }) {
  const [isExpansionEnabled, setIsExpansionEnabled] = useState(false);

  const handleCountChange = (id, change) => {
    setTokens(prevTokens =>
      prevTokens.map(token =>
        token.id === id ? { ...token, count: Math.max(0, token.count + change) } : token
      )
    );
  };

  const toggleExpansion = () => setIsExpansionEnabled(!isExpansionEnabled);

  const getTokensWithCount = () => tokens.filter(token => token.count > 0);
  const getTokensWithoutCount = () => tokens.filter(token => token.count === 0);

  const startRound = () => {
    // Call the parent function passed as a prop
    toggleScreen(true);
  };

  const resetBag = () => {
    setTokens(initialTokenState);
  };

  return (
    <>
      {/* Toggle button for expansion */}
      <div className="expansion-toggle">
        Expansion Tokens <ToggleSwitch checked={isExpansionEnabled} onChange={toggleExpansion} />
      </div>
      <h2>Bag</h2>
      <div className="tokens active-tokens">
        {getTokensWithCount().map((token, index) => (
          // Check for expansion or non-expandable token
          (isExpansionEnabled || !token.isExpansion) && (
            <div key={index}>
              <button onClick={() => handleCountChange(token.id, -1)}><IconCircleMinus color="black" size={48} /></button>
              <IconWithCounter image={token.imageurl} desc={token.name} number={token.count} />
              <button onClick={() => handleCountChange(token.id, 1)}><IconCirclePlus color="black" size={48} /></button>
            </div>
          )
        ))}
      </div>
      <h2>Stash</h2>
      <div className="tokens inactive-tokens">
        {getTokensWithoutCount().map((token, index) => (
          // Check for expansion or non-expandable token
          (isExpansionEnabled || !token.isExpansion) && (
            <div key={index}>
              <IconWithCounter image={token.imageurl} desc={token.name} number={token.count} />
              <button onClick={() => handleCountChange(token.id, 1)}><IconCirclePlus color="white" size={48} /></button>
            </div>
          )
        ))}
      </div>
      <div className="button-container">
        <button onClick={resetBag}>Reset bag</button>
        <button onClick={startRound}>Start round</button>
      </div>
    </>
  )
}

export default SetupScreen;